import { RootState } from '../../services/store/store';

export const priceFormat = (price: number) => {
  const formatedPrice = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB'
  }).format(price);
  return formatedPrice;
};

export const totalPriceSelector = (state: RootState) => {
  const {
    cart: { items },
    delivery: { deliveryMethods, selectedDeliveryId }
  } = state;

  const deliveryPrice = deliveryMethods.find(method => method.id === selectedDeliveryId)?.price ?? 0;
  return deliveryPrice + items.reduce((acc, item) => acc + item.price * item.qty, 0);
};

//c i18next функция не нужна
export const getItemsCase = ((qty: number) => {
  switch (qty) {
    case 1:
      return 'товар';
    case 2:
    case 3:
    case 4:
      return 'товара';

    default:
      return 'товаров';
  }
});

export const getBackButtonText = ((path: string) => {
  switch (path) {
    case '/cart':
      return 'Назад к списку товаров';
    case '/delivery':
      return 'Назад к списку покупок';
    default:
      return 'Текст не определен'
  }
})

export const getSubmitButtonText = ((path: string) => {
  switch (path) {
    case '/cart':
    case '/delivery':
      return 'Продолжить оформление';
    case '/checkout':
      return 'Оформить заказ';
    default:
      return 'Текст не определен';
  }
})

export const getPath = (direction: string, location: string) => {
  if (direction === 'next') {
    switch (location) {
      case '/cart':
        return '/delivery';
      case '/delivery':
        return '/checkout';
      default:
        return '/';
    }
  }
  switch (location) {
    case '/cart':
      return '/';
    case '/delivery':
      return '/cart';
    case '/checkout':
      return '/delivery';
    default:
      return '/';
  }
}
