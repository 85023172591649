import { TAppActions } from '../store/store';
import { TItem } from '../../types/types';

import {
  GET_ITEMS_REQUEST,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAILED
} from '../actions/items'

interface ICatalogueState {
  items: TItem[],
  itemsRequest: boolean,
  itemsFailed: boolean,
}

const initialState: ICatalogueState = {
  items: [],
  itemsRequest: false,
  itemsFailed: false,
}

export const itemsReducer = (state: ICatalogueState = initialState, action: TAppActions): ICatalogueState => {
  switch (action.type) {

    case GET_ITEMS_REQUEST: {
      return {
        ...state,
        itemsRequest: true
      };
    }

    case GET_ITEMS_SUCCESS: {
      return {
        ...state,
        itemsFailed: false,
        items: action.items,
        itemsRequest: false
      };
    }

    case GET_ITEMS_FAILED: {
      return {
        ...state,
        itemsFailed: true,
        itemsRequest: false
      };
    }

    default: {
      return state;
    }
  }
}
