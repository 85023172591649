import React from 'react';
import styles from './main-button.module.css';

interface IProps {
  type?: 'button' | 'submit' | 'reset' | undefined,
  children: React.ReactNode,
  extraClass?: string,
  inputButton?: boolean,
  secondary?: boolean,
  onClick: React.MouseEventHandler
}

export const MainButton = (props: IProps) => {
  const { type, children, extraClass, inputButton, secondary, onClick } = props;
  const className = `${extraClass} ${styles.button} ${inputButton ? styles.input : ''} ${secondary ? styles.secondary : ''}`;

  return (
    <button
      onClick={onClick}
      type={type}
      className={className}
    >
      {children}
    </button>
  );
};
