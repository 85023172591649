import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/hooks';
import { getItems } from '../../services/actions/items';
import { Route, Routes, Outlet } from 'react-router-dom';
import { Header } from '../common/header';
import { Homepage } from '../homepage/homepage';
import { Cart } from '../cart/cart';
import { Delivery } from '../delivery';
import { getDeliveryMethods } from '../../services/actions/delivery';
import { Checkout } from '../checkout';
import { ErrorBoundary } from '../../services/errorBoundaries/ErrorBoundary';

const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getItems())
    dispatch(getDeliveryMethods())
  }, [dispatch])

  return (
    <ErrorBoundary>
      <Routes>
        <Route
          path='/*'
          element={
            <>
              <Header />
              <Outlet />
            </>
          }>
          <Route index element={<Homepage />} />
          <Route path='cart' element={<Cart />} />
          <Route path='checkout' element={<Checkout extraClass='' />} />
          <Route path='delivery' element={<Delivery />} />
        </Route>

      </Routes>
    </ErrorBoundary>

  );
}

export default App;
