import { useMemo } from 'react';
import styles from './delivery-method.module.css';
import { DeliveryMethodOption } from './delivery-method-option';
import { Loader } from '../../ui/loader/loader';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/hooks';

export const DeliveryMethod = () => {
  const {
    deliveryMethods,
    deliveryMethodsRequest,
    selectedDeliveryId
  } = useAppSelector(state => state.delivery);

  const { t } = useTranslation('delivery', { useSuspense: false });

  const content = useMemo(
    () => {
      return deliveryMethodsRequest ? (
        <Loader size='large' />
      ) : (
        <ul className={styles.options}>
          {deliveryMethods.map((item, index) => {
            return (
              <DeliveryMethodOption
                key={index}
                {...item}
                checked={item.id === selectedDeliveryId}
              />
            );
          })}
        </ul>
      );
    },
    [deliveryMethodsRequest, deliveryMethods, selectedDeliveryId]
  );

  return (
    <div className={`${styles.container}`}>
      <h3 className={styles.title}>{t('shippingMethod')}</h3>
      {content}
    </div>
  );
};
