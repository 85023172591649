import { TItem } from '../../types/types';
import { getItemsRequest } from '../fakeApi';
import { AppDispatch } from '../store/store';

export const GET_ITEMS_REQUEST = 'GET_ITEMS_REQUEST';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAILED = 'GET_ITEMS_FAILED';

interface IGetItemsRequest {
  readonly type: typeof GET_ITEMS_REQUEST;
}

interface IGetItemsSuccess {
  readonly type: typeof GET_ITEMS_SUCCESS;
  readonly items: TItem[];
}

interface IGetItemsFailed {
  readonly type: typeof GET_ITEMS_FAILED;
}

export type TItemsActions =
  | IGetItemsRequest
  | IGetItemsSuccess
  | IGetItemsFailed

export function getItems() {
  return function (dispatch: AppDispatch) {
    dispatch({ type: GET_ITEMS_REQUEST });
    getItemsRequest()
      .then((res) => {
        if (res && res.success) {
          dispatch({
            type: GET_ITEMS_SUCCESS,
            items: res.data
          })
        }
      })
  }
}
