import { combineReducers } from 'redux';
import { languageReducer } from './language';
import { itemsReducer } from './items';
import { cartReducer } from './cart';
import { checkoutReducer } from './checkout';
import { deliveryReducer } from './delivery';

export const rootReducer = combineReducers({
  language: languageReducer,
  catalogue: itemsReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  delivery: deliveryReducer
})
