/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { SET_LANGUAGE } from '../../services/actions/language';
import styles from './language-button.module.css'

interface IProps {
  language: string;
}

export const LanguageButton = (props: IProps) => {
  const { language = 'RU' } = props;
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { language: selectedLanguage = 'RU' } = useAppSelector((store) => store.language);

  const [isActive, setIsActive] = useState(false);


  useEffect(() => {
    try {
      setIsActive(language.toLowerCase() === selectedLanguage.toLowerCase())
    } catch {
      return
    }
  }, [selectedLanguage])

  const selectLanguage = () => {
    if (selectedLanguage.toLowerCase() === language.toLocaleLowerCase()) return;
    dispatch({
      type: SET_LANGUAGE,
      payload: language.toLowerCase()
    })
    i18n.changeLanguage(language.toLowerCase());
  }

  if (!selectedLanguage) return null;

  return (
    <button
      className={styles.button_language}
      onClick={selectLanguage}
    >
      <p className={`${isActive ? styles.button_active : ''}`}>
        {language.toUpperCase()}
      </p>
    </button>
  )
}
