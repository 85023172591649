import { getDeliveryMethodsRequest } from '../fakeApi';
import { AppDispatch } from '../store/store';
import { TDeliveryMethod } from '../../types/types';

export const GET_DELIVERY_METHODS = 'GET_DELIVERY_METHODS';
export const GET_DELIVERY_METHODS_FAILED = 'GET_DELIVERY_METHODS_FAILED';
export const GET_DELIVERY_METHODS_SUCCESS = 'GET_DELIVERY_METHODS_SUCCESS';

export const SET_DELIVERY_METHOD = 'SET_DELIVERY_METHOD';
export const SET_DELIVERY_FORM_VALUE = 'SET_DELIVERY_FORM_VALUE';

interface IGetDeliveryMethods {
  readonly type: typeof GET_DELIVERY_METHODS;
}

interface IGetDeliveryMethodsSuccess {
  readonly type: typeof GET_DELIVERY_METHODS_SUCCESS;
  readonly methods: TDeliveryMethod[];
}

interface IGetDeliveryMethodsFailed {
  readonly type: typeof GET_DELIVERY_METHODS_FAILED;
}

interface ISetDeliveryMethod {
  readonly type: typeof SET_DELIVERY_METHOD;
  readonly id: string;
}

interface ISetDeliveryFormValue {
  readonly type: typeof SET_DELIVERY_FORM_VALUE;
  readonly field: string;
  readonly value: string;

}

export type TDeliveryActions =
  | IGetDeliveryMethods
  | IGetDeliveryMethodsSuccess
  | IGetDeliveryMethodsFailed
  | ISetDeliveryMethod
  | ISetDeliveryFormValue

export function getDeliveryMethods() {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: GET_DELIVERY_METHODS
    });

    getDeliveryMethodsRequest()
      .then(res => {
        if (res && res.success) {
          dispatch({
            type: GET_DELIVERY_METHODS_SUCCESS,
            methods: res.data
          });
        } else {
          dispatch({
            type: GET_DELIVERY_METHODS_FAILED
          });
        }
      });
  };
}
