import { TItem } from '../../types/types';
import { applyPromoCodeRequest } from '../fakeApi';
import { TPromoCode } from '../fakeApi';
import { AppDispatch } from '../store/store';

export const INCREASE_ITEM = 'INCREASE_ITEM';
export const DECREASE_ITEM = 'DECREASE_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const ADD_ITEM = 'ADD_ITEM';

export const APPLY_PROMO_REQUEST = 'APPLY_PROMO_REQUEST';
export const APPLY_PROMO_SUCCESS = 'APPLY_PROMO_SUCCESS';
export const APPLY_PROMO_FAILED = 'APPLY_PROMO_FAILED';
export const CANCEL_PROMO = 'CANCEL_PROMO';

export const RESET_CART = 'RESET_CART';

interface IIncreaseItem {
  readonly type: typeof INCREASE_ITEM;
  readonly id: number;
}

interface IDecreaseItem {
  readonly type: typeof DECREASE_ITEM;
  readonly id: number;
}

interface IDeleteItem {
  readonly type: typeof DELETE_ITEM;
  readonly id: number;
}

interface IAddItem {
  readonly type: typeof ADD_ITEM;
  readonly payload: TItem;
}

interface IApplyPromoRequest {
  readonly type: typeof APPLY_PROMO_REQUEST;
}

interface IApplyPromoSuccess {
  readonly type: typeof APPLY_PROMO_SUCCESS;
  readonly value: {
    code: TPromoCode,
    discount: number,
  }
}

interface IApplyPromoFailed {
  readonly type: typeof APPLY_PROMO_FAILED;
}

interface ICancelPromo {
  readonly type: typeof CANCEL_PROMO;
}

interface IResetCart {
  readonly type: typeof RESET_CART;
}

export type TCartActions =
  | IIncreaseItem
  | IDecreaseItem
  | IDeleteItem
  | IAddItem
  | IApplyPromoRequest
  | IApplyPromoSuccess
  | IApplyPromoFailed
  | ICancelPromo
  | IResetCart

export function applyPromo(code: TPromoCode) {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: APPLY_PROMO_REQUEST,
      // code
    });
    applyPromoCodeRequest(code)
      .then((res) => {
        if (res && res.success) {
          dispatch({
            type: APPLY_PROMO_SUCCESS,
            value: { ...res }
          });
        } else {
          dispatch({
            type: APPLY_PROMO_FAILED
          });
        }
      });
  };
}
