import styles from './promo-button.module.css';
import closeIcon from '../../images/close.svg';
import { CANCEL_PROMO } from '../../services/actions/cart';
import { useAppDispatch } from '../../hooks/hooks';

interface IProps {
  children?: JSX.Element;
  extraClass?: string;
}

export const PromoButton = (props: IProps) => {
  const { children, extraClass } = props;
  const dispatch = useAppDispatch();

  const cancelPromo = () => {
    dispatch({ type: CANCEL_PROMO });
  };
  return (
    <button type='button' className={`${styles.button} ${extraClass}`} onClick={cancelPromo}>
      {children}
      <img className={styles.close} src={closeIcon} alt='кнопка закрытия' />
    </button>
  );
};
