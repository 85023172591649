/* eslint-disable react-hooks/exhaustive-deps */
import styles from './modal.module.css';
import ReactDOM from 'react-dom';
import { ModalOverlay } from '../../ui/modal-overlay/modal-overlay';
import { priceFormat, totalPriceSelector } from '../common/utils';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { RESET_CART } from '../../services/actions/cart';

const modalRoot = document.getElementById('react-modals');

interface IProps {
  number: number;
  extraClass?: string;
}

export const Modal = ({ number, extraClass }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const totalPrice = useAppSelector(totalPriceSelector);

  useEffect(() => {
    window.addEventListener('keydown', handleEvents);

    return () => {
      window.removeEventListener('keydown', handleEvents);
    }
  }, [])

  const handleEvents = () => {
    dispatch({ type: RESET_CART });
    navigate('/')
  }

  return ReactDOM.createPortal(
    <section
      className={`${styles.container} ${extraClass}`}
      onClick={handleEvents}
      onKeyDown={handleEvents}
    >
      <div className={styles.modal}>
        <h2 className={styles.title}>Спасибо за заказ!</h2>
        <p className={styles.text}>Номер заказа:</p>
        <p className={styles.number}>{number}</p>
        <p className={styles.text}>Итоговая сумма:</p>
        <p className={styles.price}>{priceFormat(totalPrice)}</p>
      </div>
      <ModalOverlay />
    </section>,
    modalRoot as Element
  );
};
