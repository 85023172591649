import React from 'react';
import styles from './amount-button.module.css';

interface IProps {
  children: React.ReactNode;
  extraClass?: React.CSSProperties;
  onClick: React.MouseEventHandler;
}

export const AmountButton = ({ children, extraClass, onClick }: IProps) => {
  return (
    <button type='button' onClick={onClick} className={`${styles.button} ${extraClass}`}>
      {children}
    </button>
  );
};
