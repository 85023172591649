import React from 'react';
import { useTranslation } from 'react-i18next';
import { TLanguage } from '../../types/types';
import styles from './checkout-product.module.css';

interface IProps {
  src: string;
  description: TLanguage;
  qty: number;
  extraClass?: string;
}

export const CheckoutProduct = ({ src, description, qty, extraClass }: IProps) => {
  const { i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;

  return (
    <div className={`${styles.product} ${extraClass}`}>
      <div className={styles.leftbox}>
        <img className={styles.img} src={src} alt='фото товара.' />
        <p className={styles.text}>{description[lang as keyof TLanguage]}</p>
      </div>
      <p className={styles.count}>×{qty}</p>
    </div>
  );
};
