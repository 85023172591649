export interface ILoaderSizes {
  small: number;
  medium: number;
  large: number;
  huge: number;
}

export const loaderSizes: ILoaderSizes = {
  small: 16,
  medium: 24,
  large: 40,
  huge: 150
};

export enum ELoaderSizes {
  small = 16,
  medium = 24,
  large = 40,
  huge = 150
};
