import { TAppActions } from '../store/store';
import { TDeliveryMethod } from '../../types/types';

import {
  GET_DELIVERY_METHODS,
  GET_DELIVERY_METHODS_FAILED,
  SET_DELIVERY_FORM_VALUE,
  SET_DELIVERY_METHOD,
  GET_DELIVERY_METHODS_SUCCESS
} from '../actions/delivery';

interface IDeliveryState {
  deliveryMethods: TDeliveryMethod[],
  deliveryMethodsRequest: boolean,
  deliveryMethodsFailed: boolean,
  selectedDeliveryId: string | null,
  deliveryForm: {
    name: string,
    phone: string,
    address: string,
    unitNumber: string,
    intercom: string,
    floor: string
  }
};

const deliveryInitialState: IDeliveryState = {
  deliveryMethods: [],
  deliveryMethodsRequest: false,
  deliveryMethodsFailed: false,
  selectedDeliveryId: '2',
  deliveryForm: {
    name: '',
    phone: '',
    address: '',
    unitNumber: '',
    intercom: '',
    floor: ''
  }
};

export const deliveryReducer = (state: IDeliveryState = deliveryInitialState, action: TAppActions): IDeliveryState => {
  switch (action.type) {
    case GET_DELIVERY_METHODS: {
      return {
        ...state,
        deliveryMethodsFailed: false,
        deliveryMethodsRequest: true
      };
    }
    case GET_DELIVERY_METHODS_FAILED: {
      return {
        ...state,
        deliveryMethodsFailed: true,
        deliveryMethodsRequest: false
      };
    }
    case GET_DELIVERY_METHODS_SUCCESS: {
      return {
        ...state,
        deliveryMethods: action.methods,
        deliveryMethodsRequest: false,
        selectedDeliveryId:
          !!action.methods.length && state.selectedDeliveryId === null
            // ? action.methods[0].id
            ? null
            : state.selectedDeliveryId
      };
    }
    case SET_DELIVERY_METHOD: {
      return {
        ...state,
        selectedDeliveryId: action.id
      };
    }
    case SET_DELIVERY_FORM_VALUE: {
      return {
        ...state,
        deliveryForm: {
          ...state.deliveryForm,
          [action.field]: action.value
        }
      };
    }
    default: {
      return state;
    }
  }
};
