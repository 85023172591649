import style from './loader.module.css';
import { LoaderSvg } from './loader.svg';
import { ELoaderSizes } from '../../data/loaderSizes';

interface IProps {
  size: keyof typeof ELoaderSizes;
  inverse?: boolean;
}

export const Loader = ({ size, inverse = false }: IProps) => {
  const loaderColor = inverse ? '#fff' : '#3C39EC';
  const wrapperStyleKey = 'wrapper_' + size;

  return (
    <div className={style[wrapperStyleKey]}>
      <LoaderSvg color={loaderColor} size={ELoaderSizes[size]} />
    </div>
  );
};
