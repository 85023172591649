import styles from './checkout-address.module.css';
import { useAppSelector } from '../../hooks/hooks';
import { useTranslation } from 'react-i18next';
import { TDeliveryMethod } from '../../types/types';

export const CheckoutAddress = ({ extraClass }: { extraClass: string }) => {
  const { deliveryForm, deliveryMethod } = useAppSelector(state => ({
    deliveryForm: state.delivery.deliveryForm,
    deliveryMethod: state.delivery.deliveryMethods.find(method => method.id === state.delivery.selectedDeliveryId) as TDeliveryMethod
  }));
  const { t, i18n } = useTranslation(['checkout', 'delivery'], { useSuspense: false })
  const lang = i18n.resolvedLanguage;

  return (
    <ul className={`${styles.container} ${extraClass}`}>
      <li className={styles.textbox}>
        <h3 className={styles.title}>{t('shippingInfo')}</h3>
        <p className={styles.text}>{deliveryForm.address}</p>
        <div className={styles.addressInfo}>
          <p className={styles.text}>
            <span className={styles.span}>{t('suite')}:</span>
            {deliveryForm.unitNumber}
          </p>
          <p className={styles.text}>
            <span className={styles.span}>{t('intercom', { ns: 'delivery' })}: </span>
            {deliveryForm.intercom}
          </p>
          <p className={styles.text}>
            <span className={styles.span}>{t('floor', { ns: 'delivery' })}: </span>
            {deliveryForm.floor}
          </p>
        </div>
        <p className={styles.text}>{deliveryForm.name}</p>
        <p className={styles.text}>{deliveryForm.phone}</p>
      </li>
      <li className={styles.textbox}>
        <h3 className={styles.title}>{t('deliveryType')}</h3>
        <p className={styles.text}>{deliveryMethod.text[lang as keyof TDeliveryMethod['text']]}</p>
        <p className={styles.text}>{deliveryMethod.duration[lang as keyof TDeliveryMethod['duration']]}</p>
      </li>
    </ul>
  );
};
