import {
  ADD_ITEM,
  DELETE_ITEM,
  INCREASE_ITEM,
  DECREASE_ITEM,
  APPLY_PROMO_REQUEST,
  APPLY_PROMO_SUCCESS,
  APPLY_PROMO_FAILED,
  CANCEL_PROMO,
  RESET_CART
} from '../actions/cart'

import { TAppActions } from '../store/store';
import { TItem } from '../../types/types';

type TItemInCart = TItem & {
  qty: number;
}

interface ICartState {
  items: TItemInCart[],
  totalQty: number,
  promoCode: string | null,
  promoDiscount: number | null,
  promoRequest: boolean,
  promoFailed: boolean,
}

const initialState: ICartState = {
  items: [],
  totalQty: 0,
  promoCode: null,
  promoDiscount: null,
  promoRequest: false,
  promoFailed: false,
}

export const cartReducer = (state: ICartState = initialState, action: TAppActions) => {
  switch (action.type) {
    case ADD_ITEM:

      return {
        ...state,
        items: [{
          ...action.payload,
          qty: 1
        }, ...state.items],
        totalQty: state.totalQty + 1
      }

    case INCREASE_ITEM: {
      const { id } = action;

      return ({
        ...state,
        items: [...state.items].map((item) =>
          item.id === id ? { ...item, qty: item.qty + 1 } : item
        ),
        totalQty: state.totalQty + 1
      })
    }

    case DECREASE_ITEM: {
      const { id } = action;

      return ({
        ...state,
        items: [...state.items].map((item) =>
          item.id === id ? { ...item, qty: item.qty - 1 } : item
        ),
        totalQty: state.totalQty - 1
      })
    }

    case DELETE_ITEM: {
      const { id } = action;
      const qty = state.items.find(item => item.id === id)?.qty ?? 0

      return {
        ...state,
        items: [...state.items].filter(item => item.id !== id),
        totalQty: state.totalQty - qty
      };
    }

    case APPLY_PROMO_FAILED: {
      return {
        ...state,
        promoRequest: false,
        promoFailed: true,
        promoDiscount: null,
        promoCode: ''
      };
    }

    case APPLY_PROMO_REQUEST: {
      return {
        ...state,
        promoFailed: false,
        promoRequest: true
      };
    }

    case APPLY_PROMO_SUCCESS: {
      return {
        ...state,
        promoRequest: false,
        promoCode: action.value.code,
        promoDiscount: action.value.discount
      };
    }

    case CANCEL_PROMO: {
      return {
        ...state,
        promoCode: '',
        promoDiscount: null
      };
    }

    case RESET_CART: {
      return {
        ...state,
        items: [],
        totalQty: 0
      }
    }

    default: {
      return state;
    }
  }
}
