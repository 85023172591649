import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../hooks/hooks';
import { SET_DELIVERY_METHOD } from '../../services/actions/delivery';
import { TDeliveryMethod, TLanguage } from '../../types/types';
import { priceFormat } from '../common/utils';
import styles from './delivery-method-option.module.css';

type TSelectedDelivery = TDeliveryMethod & {
  checked: boolean
}

export const DeliveryMethodOption = ({ thumb, id, text, duration, price, checked }: TSelectedDelivery) => {
  const dispatch = useAppDispatch();
  const onClick = () => {
    dispatch({ type: SET_DELIVERY_METHOD, id });
  };

  const { i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;

  return (
    <li className={`${styles.option} ${checked && styles['option-checked']} `}>
      <input
        name='method'
        type='radio'
        id={id}
        className={styles.input}
        checked={checked}
        onChange={onClick}
      />
      <label htmlFor={id}>
        <div className={styles.leftbox}>
          <img className={styles.img} src={thumb} alt='изображение способа доставки.' />
          <p className={styles.text}>{text[lang as keyof TLanguage]}</p>
        </div>
      </label>
      <p className={styles.duration}>{duration[lang as keyof TLanguage]}</p>
      <p className={styles.price}>{priceFormat(price)}</p>
    </li>
  );
};
