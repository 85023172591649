import styles from './inputs-box.module.css';
import { Input } from '../../ui/input/input';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { SET_DELIVERY_FORM_VALUE } from '../../services/actions/delivery';
import { useTranslation } from 'react-i18next';

export const InputsBox = () => {
  const { deliveryForm } = useAppSelector(state => state.delivery);
  const { t } = useTranslation(['delivery', 'common'], { useSuspense: false });

  const dispatch = useAppDispatch();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: SET_DELIVERY_FORM_VALUE, field: e.target.name, value: e.target.value });
  };
  return (
    <div className={`${styles.container}`}>
      <ul className={styles.row}>
        <li className={`${styles.input} ${styles.inputFlex}`}>
          <div className={styles.input}>
            <label className={styles.label} htmlFor='unitNumber'>
              {t('suiteNumber')}
            </label>
            <Input
              onChange={onChange}
              name={'unitNumber'}
              value={deliveryForm.unitNumber}
              extraClass={styles.input}
              type='text'
              id='unitNumber'
            />
          </div>
          <div className={styles.input}>
            <label className={styles.label} htmlFor='intercom'>
              {t('intercom')}
            </label>
            <Input
              onChange={onChange}
              name={'intercom'}
              value={deliveryForm.intercom}
              extraClass={styles.input}
              type='text'
              id='intercom'
            />
          </div>
        </li>
        <li className={`${styles.input} ${styles.floor}`}>
          <label className={styles.label} htmlFor='floor'>
            {t('floor')}
          </label>
          <Input
            onChange={onChange}
            name={'floor'}
            value={deliveryForm.floor}
            extraClass={styles.input}
            type='text'
            id='floor'
          />
        </li>
      </ul>
      <ul className={styles.row}>
        <li className={styles.input}>
          <label className={styles.label} htmlFor='name'>
            {t('name')}
          </label>
          <Input
            onChange={onChange}
            name={'name'}
            value={deliveryForm.name}
            type='text'
            extraClass={styles.input}
            id='name'
            placeholder={t('placeholderEnterName') as string}
          />
        </li>
        <li className={styles.input}>
          <label className={styles.label} htmlFor='phone'>
            {t('phone')}
          </label>
          <Input
            onChange={onChange}
            name={'phone'}
            value={deliveryForm.phone}
            extraClass={styles.input}
            type='tel'
            id='phone'
            placeholder='+7'
          />
        </li>
      </ul>
    </div>
  );
};
