import styles from './index.module.css';
import React, { useState, useRef, useEffect } from 'react';
import { withYMaps } from 'react-yandex-maps';
import { MainButton } from '../../ui/main-button/main-button';
import { Input } from '../../ui/input/input';
import { useTranslation } from 'react-i18next';

//FIX: any
export const MapSuggestComponent = withYMaps<any>(({ onChange, value, ymaps }) => {
  const { t } = useTranslation(['delivery', 'common'], { useSuspense: false });

  //FIX: any
  const inputRef: any = useRef(null);
  const [_value, _setValue] = useState(value);

  useEffect(
    () => {
      _setValue(value);
    },
    [value]
  );

  useEffect(
    () => {
      new ymaps.SuggestView('suggest');
    },
    [ymaps.SuggestView]
  );

  const onBlur = () => {
    setTimeout(() => onChange(inputRef.current.value), 0);
  };

  const onEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    _setValue(e.target.value);
  };

  return (
    <>
      <Input
        type='text'
        placeholder={t('enterAddress') as string}
        inputWithBtn={true}
        value={_value}
        extraClass={styles.input}
        onChange={onEdit}
        inputRef={inputRef}
        onBlur={onBlur}
        id='suggest'
      />
      <MainButton type='button' inputButton={true} onClick={onBlur}>
        {t('search')}
      </MainButton>
    </>
  );
},
  true,
  ['SuggestView', 'geocode', 'coordSystem.geo']
);
