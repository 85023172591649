import styles from './header.module.css'
import cartIcon from '../../images/cart.svg'
// import wishListIcon from '../../images/wishlist.svg'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../hooks/hooks';
import { LanguageButton } from '../../ui/language-button/language-button';

export const Header = () => {
  const { totalQty } = useAppSelector(store => store.cart)

  return (
    <nav className={styles.header}>
      <Link to='/' className={styles.link}>
        <h1>DEMOSHOP</h1>
      </Link>

      <div className={styles.container}>
        {/* <Link to='wishlist' className={styles.link} title='Избранное'>
          <img src={wishListIcon} alt='Избранное' />
        </Link> */}

        <section className={styles.section_languages}>
          <LanguageButton language='EN' />
          <LanguageButton language='RU' />
        </section>

        <Link to='cart' className={styles.link} title='Корзина'>
          <img src={cartIcon} alt='Корзина' />
          {totalQty > 0 && (
            <span className={styles.span_qty}>{totalQty}</span>
          )}
        </Link>
      </div>
    </nav>
  )
}
