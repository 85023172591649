import pic1 from '../images/prod-1.jpg';
import pic2 from '../images/prod-2.jpg';
import rec1 from '../images/rec-1.png';
import rec2 from '../images/rec-2.png';
import rec3 from '../images/rec-3.png';
import rec4 from '../images/rec-4.png';
import { TItem } from '../types/types';

export const items: TItem[] = [
  {
    id: 1,
    src: pic1,
    description: {
      ru:
        'похожая на настоящую красный Мягкая приманка в виде червя силиконовый искусственный приманки рыбный запах креветок',
      en: 'similar to the real red soft worm bait silicone artificial bait fishy shrimp smell'
    },
    price: 120
  },
  {
    id: 2,
    src: pic2,
    description: {
      ru: 'Умное кольцо из нержавеющей стали с датчиком температуры тела, модный дисплей',
      en: 'Smart stainless steel ring with body temperature sensor, fashionable display'
    },
    price: 450
  },
  {
    id: 3,
    src: rec1,
    price: 640,
    description: {
      ru: 'Деревянная подушка в виде бревна, деревянная текстура пня для украшения',
      en: 'Wooden pillow in the form of a log, wooden stump texture for decoration',
    },
  },
  {
    id: 4,
    src: rec2,
    price: 480,
    description: {
      ru: 'Забавная 3D имитация, закусочный хлеб, мягкая подушка в форме поясницы',
      en: 'Funny 3D simulation, snack bread, soft pillow in the shape of a loin',
    },
  },
  {
    id: 5,
    src: rec3,
    price: 960,
    description: {
      ru: '3D моделирование формы еды плюшевая подушка креативная курица колбаса',
      en: '3D modeling of food shape plush pillow creative chicken sausage',
    },
  },
  {
    id: 6,
    src: rec4,
    price: 360,
    description: {
      ru: 'Забавная Мужская футболка Роберт Паттинсон стоячий мем',
      en: 'Funny Men\'s T-shirt Robert Pattinson Stand-up Meme',
    },
  }
]
