import { items } from '../data/items'
import { deliveryMethods } from '../data/delivery-methods';
import { TDeliveryMethod, TItem } from '../types/types';

type TGetItemsRes = {
  success: boolean,
  data: TItem[],
}

export const getItemsRequest = async (): Promise<TGetItemsRes> => {
  return await new Promise(resolve => {
    setTimeout(() => {
      resolve({
        success: true,
        data: [...items]
      })
    }, 1500);
  })
}

type TPromoCodes = {
  PROMO10: number,
  PROMO15: number,
  PROMO20: number,
  PROMO666: number
}

const promoCodes: TPromoCodes = {
  PROMO10: 10,
  PROMO15: 15,
  PROMO20: 20,
  PROMO666: 100
};

export type TPromoCode = keyof typeof promoCodes;
export type TApplyPromoResult = {
  success: boolean,
  code: TPromoCode,
  discount: number,
}

export const applyPromoCodeRequest = async (code: TPromoCode): Promise<TApplyPromoResult> => {
  const result: TApplyPromoResult = { success: true, code, discount: 0 };
  if (~Object.keys(promoCodes).indexOf(code)) {
    result.discount = promoCodes[code];
  } else {
    result.success = false;
    result.discount = 0;
    result.code = code;
  }
  return await new Promise(resolve =>
    setTimeout(() => {
      resolve(result);
    }, 1500)
  );
};

export type TOrderCheckoutResult = {
  success: boolean,
  data: { id: number },
};

export const orderCheckoutRequest: () => Promise<TOrderCheckoutResult> = async () => {
  const result: TOrderCheckoutResult = { success: true, data: { id: Math.floor(Math.random() * 1000) + 2033 } };

  return await new Promise(resolve =>
    setTimeout(() => {
      resolve(result);
    }, 1500)
  );
};

type TGetDeliveryMethodsRes = {
  success: boolean,
  data: TDeliveryMethod[]
}

export const getDeliveryMethodsRequest = async (): Promise<TGetDeliveryMethodsRes> => {
  return await new Promise(resolve => {
    setTimeout(() => {
      resolve({
        success: true,
        data: deliveryMethods
      });
    }, 1500);
  });
};
