import { TAppActions } from '../store/store';
import { SET_LANGUAGE } from '../actions/language';

interface ILanguageState {
  language: string;
}

const initState: ILanguageState = {
  language: 'ru'
}

export const languageReducer = (state: ILanguageState = initState, action: TAppActions): ILanguageState => {
  if (action.type === SET_LANGUAGE) {
    return {
      ...state,
      language: action.payload
    }
  }

  return state;
}
