/* eslint-disable react-hooks/exhaustive-deps */
import styles from './cart.module.css'
import React, { useMemo, useRef, useCallback, ReactElement } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { CartItemCard } from './cart-item-card';
import { Input } from '../../ui/input/input';
import { MainButton } from '../../ui/main-button/main-button';
import { PromoButton } from '../../ui/promo-button/promo-button';
import { Loader } from '../../ui/loader/loader';
import { applyPromo } from '../../services/actions/cart';
import { TotalPrice } from './total-price';
import { useTranslation } from 'react-i18next';

export const Cart = () => {
  const dispatch = useAppDispatch();
  const { t, ready } = useTranslation(['cart', 'common'], { useSuspense: false })

  const {
    items,
    promoCode,
    promoDiscount,
    promoRequest,
    promoFailed,
  } = useAppSelector(store => store.cart);

  // FIX: разобраться с any
  const inputRef: any = useRef(null);

  const applyPromoCode = useCallback(
    () => {
      if (inputRef.current !== null) {
        dispatch(applyPromo(inputRef.current.value));
      }
    },
    [inputRef, dispatch]
  );

  const content = useMemo(
    () => {
      return items.map((item, index) => {
        return <CartItemCard key={index} {...item} />
      })
    },
    [items]
  )

  const promoCodeStatus = useMemo(
    () => {
      return promoFailed ? (
        <p className={styles.text}>{t('promocodeError')}</p>
      ) : promoRequest ? (
        ''
      ) : !!promoCode && !!promoDiscount ? (
        <p className={styles.text}>{t('promocodeSuccess')}</p>
      ) : (
        ''
      );
    },
    [promoRequest, promoDiscount, promoFailed, promoCode]
  );

  if (!ready) return null;

  return (
    <section className={styles.cart_section} >
      <h2>{t('cart')}</h2>
      {content}
      <div className={styles.promo}>
        <div className={styles.inputWithBtn}>
          <Input
            type='text'
            placeholder={t('enterPromocode') as string}
            extraClass={styles.input}
            inputWithBtn={true}
            inputRef={inputRef as unknown as string}
          />
          <MainButton
            type='button'
            extraClass={styles.promo_button}
            inputButton={true}
            onClick={applyPromoCode}
          >
            {promoRequest ? <Loader size='small' inverse={true} /> : `${t('apply')}`}
          </MainButton>
        </div>

        {!!promoCode && !!promoDiscount && (
          <PromoButton extraClass={styles.promocode}>{promoCode as unknown as ReactElement}</PromoButton>
        )}
      </div>
      {promoCodeStatus}
      <div className={styles.hint}>
        {promoCode ? '' : <small>{t('promocodes')} 'PROMO10', 'PROMO15', 'PROMO20', 'PROMO666'</small>}
      </div>
      <TotalPrice />
    </section>
  )
}
