import React, { Component, ErrorInfo, ReactNode } from 'react';

type StateType = {
  error: null | Error;
  errorInfo: null | ErrorInfo;
};

type PropsType = {
  children: ReactNode;
};

export class ErrorBoundary extends Component<PropsType, StateType> {
  state: StateType = { error: null, errorInfo: null };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    console.log('error', error);
    console.log('errorInfo', errorInfo);
  }

  render() {
    const { children } = this.props;

    if (this.state.errorInfo) {
      return (
        <div className='h-100 d-flex align-items-center justify-content-center'>
          <div>
            <h3>Oops something went wrong</h3>
            <h1>Please refresh page</h1>
            <p>{String(this.state.error)}</p>
          </div>
        </div>
      );
    }
    return children;
  }
}
