import { configureStore } from '@reduxjs/toolkit';
import { TCartActions } from '../actions/cart';
import { TCheckoutActions } from '../actions/checkout';
import { TDeliveryActions } from '../actions/delivery';
import { TItemsActions } from '../actions/items';
import { TLanguageActions } from '../actions/language';
import { rootReducer } from '../reducers';

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type TAppActions =
  | TCartActions
  | TCheckoutActions
  | TDeliveryActions
  | TItemsActions
  | TLanguageActions
