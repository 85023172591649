// import { useMemo } from 'react';
import { MainButton } from '../../ui/main-button/main-button';
import styles from './total-price.module.css';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { orderCheckout } from '../../services/actions/checkout';
import { getPath, priceFormat, totalPriceSelector } from '../common/utils';
import { Loader } from '../../ui/loader/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const TotalPrice = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, ready } = useTranslation(['common', 'cart'], { useSuspense: false });
  const { pathname } = useLocation();
  const totalPrice = useAppSelector(totalPriceSelector);
  const discount = useAppSelector(state => state.cart.promoDiscount);
  const { orderCheckoutRequest } = useAppSelector(state => state.checkout);
  const price = discount ? totalPrice - totalPrice * (discount / 100) : totalPrice;

  const nextPath = getPath('next', pathname);
  const backPath = getPath('back', pathname);

  const handleSubmitButtonClick = () => {
    if (pathname === '/checkout') {
      dispatch(orderCheckout())
    } else {
      navigate(nextPath)
    }
  }

  if (!ready) return null;

  return (
    <div className={`${styles.container}`}>
      <p className={styles.text}>{t('total')}</p>
      <p className={styles.cost}>{priceFormat(price)}</p>
      <div className={styles.buttonbox}>
        <MainButton onClick={() => navigate(backPath)} type='button' secondary={true} extraClass={styles.button}>
          {t('backButtonText', { ns: 'cart' })}
        </MainButton>
        <MainButton onClick={handleSubmitButtonClick} type='button'>
          {orderCheckoutRequest ? <Loader size='small' inverse={true} /> : `${t('proceedButtonText', { ns: 'cart' })}`}
        </MainButton>
      </div>
    </div>
  );
};
