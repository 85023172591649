/* eslint-disable react-hooks/exhaustive-deps */
import styles from './homepage.module.css';
import { ItemCard } from '../common/item-card';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import cartImage from '../../images/cart.svg';
import { priceFormat } from '../common/utils';
import { MainButton } from '../../ui/main-button/main-button';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Loader } from '../../ui/loader/loader';
import { useTranslation } from 'react-i18next';
import { SET_LANGUAGE } from '../../services/actions/language';

export const Homepage = () => {
  const { t, i18n, ready } = useTranslation(['homepage', 'common'], { useSuspense: false }); //ready - true когда загрузятся файлы из /publib/locales
  const { language } = useAppSelector((store) => store.language)
  const dispatch = useAppDispatch();

  const browserLanguage = i18n.resolvedLanguage;

  if (!language) {
    dispatch({
      type: SET_LANGUAGE,
      payload: browserLanguage
    })
  }

  const navigate = useNavigate();
  const {
    itemsRequest,
    items
  } = useAppSelector((store) => store.catalogue);
  const { totalQty, items: cartItems } = useAppSelector((store) => store.cart);

  //FIX: fix 'any'
  const infoRef: any = useRef(null);
  const initTopY: any = useRef(0);
  const windowHeight: any = useRef(0);
  const divHeight: any = useRef(0);

  const [paddingState, setPaddingState] = useState(0);

  useEffect(() => {
    initTopY.current = infoRef.current.getBoundingClientRect().top;
    divHeight.current = infoRef.current.offsetHeight;
    windowHeight.current = document.documentElement.clientHeight;
    calcPadding();

    window.addEventListener('scroll', calcPadding);
    window.addEventListener('resize', getWindowHeight);

    return () => {
      window.removeEventListener('scroll', calcPadding);
      window.removeEventListener('resize', getWindowHeight);
    }
  }, [])

  const calcPadding = () => {
    const { top: topY } = infoRef.current.getBoundingClientRect()
    let padding = 0;

    padding = Math.round(windowHeight.current - (windowHeight.current / 2) - (divHeight.current / 2) - topY - 20);
    setPaddingState(padding);
  }

  const getWindowHeight = () => {
    windowHeight.current = document.documentElement.clientHeight;
  }

  const handleButtonClick = () => {
    navigate('/cart')
  }

  const totalCost = useMemo(
    () => {
      return priceFormat(cartItems.reduce((acc, item) => acc + item.price * item.qty, 0))
    },
    [cartItems]
  )

  if (itemsRequest || !ready) return (<Loader size='huge' />)

  return (
    <section className={styles.section_homepage}>
      <div className={styles.items_container}>
        {items.map((item, index) => {
          return <ItemCard key={index} {...item} />
        })}
      </div>
      <div ref={infoRef} className={styles.info_container} style={{ paddingTop: `${paddingState}px` }} >
        <img src={cartImage} alt='Корзина' className={styles.cart_image} />
        <span className={styles.total_text}>{t('total', { ns: 'common' })} {totalCost}</span>
        <span>{t('qty', { count: totalQty })}</span>
        <MainButton
          onClick={handleButtonClick}
          extraClass={styles.button_extraclass}
        >
          {t('main_button.proceed_checkout')}
        </MainButton>
      </div>

    </section >
  )
}
