import {
  orderCheckoutRequest,
} from '../fakeApi';
import { AppDispatch } from '../store/store';

export const ORDER_CHECKOUT_REQUEST = 'ORDER_CHECKOUT_REQUEST';
export const ORDER_CHECKOUT_SUCCESS = 'ORDER_CHECKOUT_SUCCESS';
export const ORDER_CHECKOUT_FAILED = 'ORDER_CHECKOUT_FAILED';

interface IOrderCheckoutRequest {
  readonly type: typeof ORDER_CHECKOUT_REQUEST;
}

interface IOrderCheckoutSuccess {
  readonly type: typeof ORDER_CHECKOUT_SUCCESS;
  readonly order: { id: number };
}

interface IOrderCheckoutFailed {
  readonly type: typeof ORDER_CHECKOUT_FAILED;
}

export type TCheckoutActions =
  | IOrderCheckoutRequest
  | IOrderCheckoutSuccess
  | IOrderCheckoutFailed

export function orderCheckout() {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: ORDER_CHECKOUT_REQUEST
    });
    orderCheckoutRequest()
      .then((res) => {
        if (res && res.success) {
          dispatch({
            type: ORDER_CHECKOUT_SUCCESS,
            order: res.data
          });
        } else {
          dispatch({
            type: ORDER_CHECKOUT_FAILED
          });
        }
      });
  };
}
