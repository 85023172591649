import React from 'react';
import styles from './modal-overlay.module.css';

interface IProps {
  extraClass?: React.CSSProperties;
}

export const ModalOverlay = (props: IProps) => {
  const { extraClass } = props;
  return (
    <div className={`${styles.overlay} ${extraClass}`} />
  )
};
