
import delivery1 from '../images/express.svg';
import delivery2 from '../images/standart.svg';
import { TDeliveryMethod } from '../types/types';

export const deliveryMethods: TDeliveryMethod[] = [
  {
    thumb: delivery1,
    id: '1',
    text: {
      ru: 'Экспресс доставка',
      en: 'Express delivery'
    },
    duration: {
      en: '7-14 days',
      ru: '7-14 дней',
    },
    price: 4000
  },
  {
    thumb: delivery2,
    id: '2',
    text: {
      ru: 'Обычная доставка',
      en: 'Regular delivery'
    },
    duration: {
      en: '30-45 days',
      ru: '30-45 дней',
    },
    price: 0
  }
]
