import styles from './item-card.module.css';
import { MainButton } from '../../ui/main-button/main-button';
import { AmountButton } from '../../ui/amount-button/amount-button';
import { priceFormat } from './utils';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { ADD_ITEM, INCREASE_ITEM, DECREASE_ITEM, DELETE_ITEM } from '../../services/actions/cart';
import { useTranslation } from 'react-i18next';
import { TItem, TLanguage } from '../../types/types';

export const ItemCard = (item: TItem) => {
  const { t } = useTranslation();

  const { src, price, id } = item;
  const { description }: { description: TLanguage } = item;
  const dispatch = useAppDispatch();
  const { items } = useAppSelector(store => store.cart);
  let { language } = useAppSelector(store => store.language);

  if (!language) return null;
  language = language.toLowerCase();

  let qty: number = 0;

  if (items.find(item => item.id === id)) {
    qty = items.find(item => item.id === id)?.qty ?? 0
  }

  const onDelete = () => {
    dispatch({
      type: DELETE_ITEM,
      id,
    })
  }

  const handleButtonClick = () => {
    dispatch({
      type: ADD_ITEM,
      payload: item,
    })
  }

  const increase = () => {
    dispatch({
      type: INCREASE_ITEM,
      id,
    })
  }

  const decrease = () => {
    if (qty === 1) {
      onDelete();
    } else {
      dispatch({
        type: DECREASE_ITEM,
        id,
      })
    }
  }

  return (
    <article className={styles.article}>
      <img className={styles.img} src={src} alt='изображение товара.' />
      <p className={styles.price}>{priceFormat(price)}</p>
      <p className={styles.text}>{description[language as keyof TLanguage]}</p>

      {qty === 0 && (
        <MainButton
          type='button'
          extraClass={styles.button}
          onClick={handleButtonClick}
        >
          {t('main_button.to_the_cart')}
        </MainButton>
      )}

      {qty !== 0 && (
        <div className={styles.amountbox}>
          <AmountButton onClick={decrease}>-</AmountButton>
          <p className={styles.amount}>{qty}</p>
          <AmountButton onClick={increase}>+</AmountButton>
        </div>
      )}
    </article>
  );
};
